@import url("https://fonts.googleapis.com/css2?family=Nunito&display=swap");

:root {
  --coffee: #ac7b58;
  --purple: #7061f7;
  --bg: #ffffff;
  --sidebar_width: 220px;
  --transition: all 0.5s ease-in-out;
  --default_pad: 15px;
  --hamburger: 40px;
  --header_height: 60px;
  --page_title_height: 60px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-family: "Nunito", sans-serif;
  font-size: 22px;
}

body {
  display: flex;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: var(--purple);
}

.landing_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.logo_icon {
  height: 61px;
  width: 61px;
  margin-top: 17.9vh;
  margin-bottom: 14.3vh;
}

.landing_header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 10px 0;
  padding-right: 10%;
}

.bg_icon {
  position: absolute;
  top: 0;
  width: 80%;
  left: 10%;
  z-index: -1;
}

.welcome_text {
  text-align: center;
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 32px;
  color: #ffffff;
  margin-bottom: 6vh;
}

.main_button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  height: 56px;
  width: 22vw;
  border-radius: 8px;
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #7061f7;
  margin-bottom: 30px;
  cursor: pointer;
}

.textRow {
  display: flex;
  width: 86vw;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 2.8vh;
}

.term {
  display: inline-block;
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  text-transform: capitalize;
  color: #ffffff;
  cursor: pointer;
}

.panel_container {
  height: 100vh;
  width: 100vw;
  max-height: 100vh;
  max-width: 100vw;
  background-color: white;
}

.sidebar_container {
  position: absolute;
  top: 0;
  left: calc(var(--sidebar_width) * -1);
  border-right: 2px solid var(--purple);
  height: 100vh;
  width: var(--sidebar_width);
  background-color: var(--bg);
  padding: var(--default_pad);
  -webkit-transition: var(--transition);
  -o-transition: var(--transition);
  transition: var(--transition);
  z-index: 2;
}

.sidebar_container.open {
  left: 0;
}

.sidebar_title {
  color: var(--purple);
  text-align: center;
  font-size: 22px;
  line-height: calc(var(--hamburger) - 6px);
  margin-right: 50px;
}

.panel_content_container {
  height: 100vh;
  width: 100vw;
  max-height: 100vh;
  max-width: 100vw;
  -webkit-transition: var(--transition);
  -o-transition: var(--transition);
  transition: var(--transition);
}

.panel_content_container.large {
  padding: 0 var(--sidebar_width);
}

.panel_content_container.medium {
  padding-right: calc(var(--sidebar_width) / 2);
  padding-left: calc(var(--sidebar_width) / 2);
}

.panel_content_container.medium.open {
  padding-right: 0;
  padding-left: var(--sidebar_width);
}

.panel_content_container.small {
  padding: 0;
}

.panel_inside {
  display: block;
  height: calc(100vh - var(--header_height));
  width: auto;
  padding: var(--default_pad);
  background-color: var(--white);
}

#hamburger-1 {
  position: absolute;
  top: var(--default_pad);
  right: calc((var(--default_pad) * 2 + var(--hamburger)) * -1);
  -webkit-transition: var(--transition);
  -o-transition: var(--transition);
  transition: var(--transition);
}

#hamburger-1 .line {
  width: var(--hamburger);
  height: 5px;
  background-color: var(--purple);
  display: block;
  margin: 5px auto;
  -webkit-transition: var(--transition);
  -o-transition: var(--transition);
  transition: var(--transition);
}

#hamburger-1:hover {
  cursor: pointer;
}

#hamburger-1.is-active {
  right: var(--default_pad);
}

#hamburger-1.is-active .line:nth-child(2) {
  opacity: 0;
}

#hamburger-1.is-active .line:nth-child(1) {
  -webkit-transform: translateY(10px) rotate(45deg);
  -ms-transform: translateY(10px) rotate(45deg);
  -o-transform: translateY(10px) rotate(45deg);
  transform: translateY(10px) rotate(45deg);
}

#hamburger-1.is-active .line:nth-child(3) {
  -webkit-transform: translateY(-10px) rotate(-45deg);
  -ms-transform: translateY(-10px) rotate(-45deg);
  -o-transform: translateY(-10px) rotate(-45deg);
  transform: translateY(-10px) rotate(-45deg);
}

.navbar_container {
  display: flex;
  height: var(--header_height);
  width: auto;
  padding: var(--default_pad);
  border-bottom: 2px solid var(--purple);
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.sidebar_items_contaier {
  padding: 20px 0;
}
.sidebar_item_container {
  margin-bottom: 15px;
}
.privacy_page {
  display: flex;
  min-height: 100vh;
  width: 100vw;
  background-color: var(--bg);
  flex-direction: column;
}
.privacy_header {
  padding: 10px;
  height: 50px;
  width: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: var(--purple);
}
.privacy_container {
  height: calc(100vh - 50px);
  padding: 20px;
  padding-left: 100px;
  padding-right: 100px;
  width: 100%;
  overflow-y: scroll;
}

.capitalize {
  text-decoration: none;
  color: #000000;
  padding: 10px;
  margin-bottom: 15px;
}

.capitalize_selected {
  text-decoration: none;
  color: var(--purple);
  padding: 10px;
  margin-bottom: 15px;
}

.page_title_container {
  height: var(--page_title_height);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.page_title_container h1 {
  display: flex;
  font-size: 32px;
  align-items: center;
}

.table_container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: scroll;
  overflow-x: hidden;
  height: calc(
    100vh - var(--header_height) - var(--page_title_height) - 2 *
      var(--default_pad)
  ) !important;
  max-height: calc(
    100vh - var(--header_height) - var(--page_title_height) - 2 *
      var(--default_pad)
  ) !important;
}

.notifications_container {
  height: calc(
    100vh - var(--header_height) - var(--page_title_height) - 2 *
      var(--default_pad)
  ) !important;
  max-height: calc(
    100vh - var(--header_height) - var(--page_title_height) - 2 *
      var(--default_pad)
  ) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.notifications_form {
  display: flex;
  flex-direction: column;
  width: 40vw;
  max-width: 100vw;
  margin-bottom: 30px;
}

.notif_title_input {
  margin-bottom: 20px;
}

.notif_body_input {
  margin-bottom: 40px;
}

input[type="text"] {
  width: 100%;
  border: 2px solid #aaa;
  border-radius: 4px;
  margin: 8px 0;
  outline: none;
  padding: 8px;
  box-sizing: border-box;
  transition: 0.3s;
}

input[type="text"]:focus {
  border-color: dodgerBlue;
  box-shadow: 0 0 8px 0 dodgerBlue;
}

textarea[type="text"] {
  width: 100%;
  border: 2px solid #aaa;
  border-radius: 4px;
  margin: 8px 0;
  outline: none;
  padding: 8px;
  box-sizing: border-box;
  transition: 0.3s;
}

textarea[type="text"]:focus {
  border-color: dodgerBlue;
  box-shadow: 0 0 8px 0 dodgerBlue;
}

.delete_image {
  cursor: pointer;
}
